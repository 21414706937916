<template>
<div>
    <b-row>
        <b-col md="12">
            <b-row>
                <b-col md="12">
                    <CCard>
                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-file-alt fa-md mr-1"></i>
                            <span class="h5">Gestión de asignación de equipo auditor</span>
                        </CCardHeader>
                        <CCardBody>
                            <b-row>
                                <b-col md="12">
                                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                        <b-form @submit.stop.prevent="handleSubmit(guardarAsignacionEquipo)">
                                            <b-row>
                                                <!-- <b-col md="8">
                                                    <validation-provider name="empresa" rules="required" v-slot="{ errors }">
                                                        <b-form-group label="Empresa:" class="mb-2">
                                                            <v-select :reduce="(listaClientes) => listaClientes.idAuditoria" label="razonSocial" placeholder="Seleccione una opción" :class="{'style-valid-select': datosAsignacionEquipo.idAuditoria,'style-invalid-select is-invalid': !!errors.length,}" v-model.lazy="datosAsignacionEquipo.idAuditoria" :options="listaClientes">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col> -->
                                                <b-col md="4">
                                                    <validation-provider name="fecha:" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                        <b-form-group label="Fecha:">
                                                            <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="datosAsignacionEquipo.fecha"></b-form-input>
                                                            <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <span>
                                                        IQC PERU notifica que para la realización de auditoría de
                                                        ________________ a la organización
                                                        {{datosAsignacionEquipo.idAuditoria && listaClientes.length > 0? listaClientes.filter((x) => x.idAuditoria == datosAsignacionEquipo.idAuditoria  )[0].empresa: '_____________________'}}
                                                        ubicada en _______________________________ que se estima para ser
                                                        realizada el
                                                        {{ datosAsignacionEquipo.fecha }} han sido seleccionados como
                                                        integrantes del equipo auditor:
                                                    </span>
                                                </b-col>
                                                <b-col md="12">
                                                    <b-button size="sm" variant="dark" class="float-right" @click="agregarEquipoAuditor">
                                                        <i class="fas fa-plus fa-sm"></i><span> Agregar</span>
                                                    </b-button>
                                                </b-col>
                                                <b-col md="12" class="mt-3">
                                                    <b-table bordered hover show-empty mediun responsive outlined :items="datosAsignacionEquipo.equipoAuditor" :fields="campoEquipoAuditor" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                        <template #cell(acciones)="param">
                                                            <b-button @click="eliminarAsignacionEquipoAuditor(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                <span class="btn-inner--icon">
                                                                    <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                                </span>
                                                            </b-button>
                                                        </template>

                                                        <template v-slot:cell(nombre)="row">
                                                            <validation-provider :name="'nombre-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group>
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model.lazy="row.item.nombre"></b-form-input>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </template>

                                                        <template v-slot:cell(idTipoAuditor)="row">
                                                            <validation-provider :name="'tipo-' + row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-select :state="getValidationState(validationContext)" :options="comboTipoAuditor" value-field="idTipoAuditor" text-field="descripcion" v-model="row.item.idTipoAuditor" size="md">
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                            </validation-provider>
                                                        </template>
                                                    </b-table>
                                                </b-col>
                                                <b-col md="12" class="mb-2">
                                                    <p>El equipo auditor debe:</p>
                                                    <ol type="a" class="mt-1" align="justify">
                                                        <li>
                                                            Examinar y verificar la estructura, las políticas, los procesos, los
                                                            procedimientos, los registros y los documentos relacionados de la
                                                            organización cliente pertinentes al sistema de gestión.
                                                        </li>
                                                        <li>
                                                            Determinar que estos cumplen todos los requisitos pertinentes al
                                                            alcance previsto de la certificación.
                                                        </li>
                                                        <li>
                                                            Determinar que los procesos y procedimientos se han establecido,
                                                            implementado y mantenido eficazmente para dar confianza en el
                                                            sistema de gestión del cliente.
                                                        </li>
                                                        <li>
                                                            Comunicar al cliente, para que actué en consecuencia, cualquier
                                                            inconsistencia entre su política, sus objetivos y sus metas
                                                            (coherentes con las expectativas del sistema de gestión u otro
                                                            documento normativo) y los resultados.
                                                        </li>
                                                    </ol>
                                                    <p align="justify">
                                                        Siguiendo para ello los lineamientos establecidos en los
                                                        procedimientos e instructivos de IQC PERU.
                                                    </p>
                                                    <p align="justify">
                                                        El auditor líder se encargará de dirigir el equipo auditor, de
                                                        coordinar la Auditoría en sus dos etapas, realizar el plan de
                                                        auditoría y asignar las tareas específicas para cada integrante del
                                                        equipo, definiendo qué procesos auditará cada uno y entregará a la
                                                        Dirección de IQC PERU los informes correspondientes.
                                                    </p>
                                                    <p align="justify">
                                                        Se reitera el deber de cumplir con lo establecido en los perfiles de
                                                        puesto según el cargo de cada uno, además de las políticas, los
                                                        compromisos y los procedimientos definidos por IQC PERU.
                                                    </p>
                                                    <p>Fecha: {{ datosAsignacionEquipo.fecha }}</p>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="firma notificado" v-slot="validationContext">
                                                        <b-form-group label="Firma notificado:">
                                                            <b-input-group>
                                                                <b-input-group-prepend v-if="datosAsignacionEquipo.urlFirmaNotificado != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosAsignacionEquipo.urlFirmaNotificado)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file1" class="text-left" v-model.lazy="datosAsignacionEquipo.firmaNotificado" v-on:change="handleFileUpload($event, '1')" :state="getValidationState(validationContext)" :placeholder="datosAsignacionEquipo.nombreFirmaNotificado? datosAsignacionEquipo.nombreFirmaNotificado: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosAsignacionEquipo.nombreFirmaNotificado? datosAsignacionEquipo.nombreFirmaNotificado: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                            </b-input-group>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="gerente general IQC PERÚ" v-slot="validationContext">
                                                        <b-form-group label="Gerente general IQC PERÚ:">
                                                            <b-input-group>
                                                                <b-input-group-prepend v-if="datosAsignacionEquipo.urlFirmaGerente != ''">
                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosAsignacionEquipo.urlFirmaGerente)" v-c-tooltip="'Descargar'">
                                                                        <i class="fas fa-download fa-xs"></i>
                                                                    </b-button>
                                                                </b-input-group-prepend>
                                                                <b-form-file ref="file2" class="text-left" v-model.lazy="datosAsignacionEquipo.firmaGerente" v-on:change="handleFileUpload($event, '2')" :state="getValidationState(validationContext)" :placeholder="datosAsignacionEquipo.nombreFirmaGerente? datosAsignacionEquipo.nombreFirmaGerente: 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosAsignacionEquipo.nombreFirmaGerente? datosAsignacionEquipo.nombreFirmaGerente: 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                <b-form-invalid-feedback>{{validationContext.errors[0]}}</b-form-invalid-feedback>
                                                            </b-input-group>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12 text-center my-3">
                                                    <b-button :to="{name: $route.params.eu ? 'ejecutar auditoria' : 'Documentos Stage 2' }" variant="dark" class=" mr-2">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        <i class="fas fa-save"></i> Guardar
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </validation-observer>
                                </b-col>
                            </b-row>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from 'axios';
import CONSTANTES from '@/Constantes.js';

export default {
    data() {
        return {
            isTemplate: true,
            datosAsignacionEquipo: {
                idAsignacionEquipo: null,
                idCliente: null,
                idAuditoria: null,
                fecha: null,
                equipoAuditor: [],

                firmaNotificado: null,
                nombreFirmaNotificado: '',
                urlFirmaNotificado: '',

                firmaGerente: null,
                nombreFirmaGerente: '',
                urlFirmaGerente: '',
            },

            campoEquipoAuditor: [{
                    key: 'index',
                    label: 'N°',
                    class: 'text-center',
                },
                {
                    key: 'nombre',
                    label: 'Nombres y Apellidos',
                    class: 'text-center',
                },
                {
                    key: 'idTipoAuditor',
                    label: 'Tipo Auditor',
                    class: 'text-center',
                },

                {
                    key: 'acciones',
                    label: '',
                    class: 'text-center',
                },
            ],

            listaClientes: [],

            comboTipoAuditor: [{
                    idTipoAuditor: 1,
                    descripcion: 'Auditor lider',
                },
                {
                    idTipoAuditor: 2,
                    descripcion: 'Auditor ',
                },
                {
                    idTipoAuditor: 3,
                    descripcion: 'Experto Tecnico',
                },
            ],
        };
    },
    methods: {
        descargarDocumento(url) {
            window.open(url);
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        handleFileUpload(e, id) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (
                    !e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)
                ) {
                    this.swat('error', 'Formato de archivo no admitido.');
                    this.$refs[`file${id}`].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', 'El tamaño del archivo no debe ser superior a 20MB');
                    this.$refs[`file${id}`].reset();
                    return;
                }
            }
        },
        obtenerAsignacionEquipo() {
            let me = this;
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/obtener-asignacion-equipo', {
                    params: {
                        idAsignacionEquipo: me.$route.params.id,
                        idCliente: me.datosAsignacionEquipo.idCliente,
                        stage: 2,
                    },
                })
                .then(function (response) {
                    me.datosAsignacionEquipo.idAsignacionEquipo = response.data[0].idAsignacionEquipo;
                    me.datosAsignacionEquipo.idCliente = response.data[0].idCliente;
                    me.datosAsignacionEquipo.idAuditoria = response.data[0].idAuditoria;
                    me.datosAsignacionEquipo.fecha = response.data[0].fecha;
                    me.datosAsignacionEquipo.equipoAuditor = JSON.parse(response.data[0].equipoAuditor);
                    me.datosAsignacionEquipo.nombreFirmaNotificado = response.data[0].nombreFirmaNotificado;
                    me.datosAsignacionEquipo.urlFirmaNotificado = response.data[0].urlFirmaNotificado;
                    me.datosAsignacionEquipo.nombreFirmaGerente = response.data[0].nombreFirmaGerente;
                    me.datosAsignacionEquipo.urlFirmaGerente = response.data[0].urlFirmaGerente;

                    if (me.$route.params.eu && me.$route.query.idAuditoria) {
                        me.datosAsignacionEquipo.isPack = true;
                        me.datosAsignacionEquipo.idAsignacionEquipo = me.$route.params.eu == 'u' ? me.$route.params.id : null;
                        me.datosAsignacionEquipo.idAuditoria = me.$route.query.idAuditoria || null
                        me.datosAsignacionEquipo.idUsuario = me.$store.state.user.uid;
                        me.datosAsignacionEquipo.sUsuario = me.$store.state.user.username;
                        me.datosAsignacionEquipo.eu = me.$route.params.eu == 'u' ? 'u' : 'i'
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                });
        },
        agregarEquipoAuditor() {
            let me = this;
            me.datosAsignacionEquipo.equipoAuditor.push({
                idEquipoAuditor: Date.now(),
                idTipoAuditor: null,
                nombre: '',
            });
        },
        guardarAsignacionEquipo() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            formData.append('folder', 'pack/documentos-stage-2/gestion-asignacion-equipo-auditor');
            formData.append('file1', me.datosAsignacionEquipo.firmaNotificado);
            formData.append('file2', me.datosAsignacionEquipo.firmaGerente);

            if (me.$route.params.id) {
                formData.append('idAsignacionEquipo', me.datosAsignacionEquipo.idAsignacionEquipo);
            }

            formData.append('idCliente', me.datosAsignacionEquipo.idCliente);
            formData.append('idAuditoria', me.datosAsignacionEquipo.idAuditoria);
            formData.append('fecha', me.datosAsignacionEquipo.fecha);
            formData.append('equipoAuditor', JSON.stringify(me.datosAsignacionEquipo.equipoAuditor));
            formData.append('nombreFirmaNotificado', me.datosAsignacionEquipo.nombreFirmaNotificado);
            formData.append('urlFirmaNotificado', me.datosAsignacionEquipo.urlFirmaNotificado);
            formData.append('stage', '2');
            formData.append('nombreFirmaGerente', me.datosAsignacionEquipo.nombreFirmaGerente);
            formData.append('urlFirmaGerente', me.datosAsignacionEquipo.urlFirmaGerente);

            if (me.$route.params.eu && me.$route.query.idAuditoria) {
                formData.append('isPack', me.datosAsignacionEquipo.isPack);
                formData.append('idUsuario', me.datosAsignacionEquipo.idUsuario);
                formData.append('sUsuario', me.datosAsignacionEquipo.sUsuario);
                formData.append('eu', me.datosAsignacionEquipo.eu);
            }

            axios
                .post(CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/registrar-asignacion-equipo', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.token}`,
                    },
                })
                .then(function (response) {
                    let color = response.data.resultado == 1 ? 'success' : 'error';
                    me.swat(color, response.data.mensaje);
                    me.disabled = false;
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.disabled = false;
                });
        },

        eliminarAsignacionEquipoAuditor(param) {
            let me = this;
            // if (this.$route.params.id) {
            //     let equipoAuditor = me.datosAsignacionEquipo.equipoAuditor.filter(x => x.estado == 2);
            //     if (equipoAuditor[param.index].idEquipoAuditor) {
            //         equipoAuditor[param.index].estado = 1
            //     } else if (!equipoAuditor[param.index].idEquipoAuditor) {
            //         equipoAuditor[param.index].estado = 0
            //         for (let e in me.datosAsignacionEquipo.equipoAuditor) {
            //             if (me.datosAsignacionEquipo.equipoAuditor[e].estado == 0) {
            //                 me.datosAsignacionEquipo.equipoAuditor.splice(e, 1);
            //             }
            //         }
            //     }
            // } else {
            me.datosAsignacionEquipo.equipoAuditor.splice(param.index, 1);
            // }
        },

        swat(icon, title) {
            this.$swal
                .mixin({
                    toast: true,
                    showConfirmButton: false,
                    position: 'bottom-right',
                    timer: 3500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer);
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                    },
                })
                .fire({
                    icon: icon,
                    title: title,
                });
        },
    },

    async mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosAsignacionEquipo.idCliente = user.uidClient;
            // await this.listarClientes();
            if (this.$route.params.id) {
                this.obtenerAsignacionEquipo();
            }
        }
    },
};
</script>
